.btn-no-style {
  padding: 0;
  margin: 0;
  background-color: none;
  background: none;
  border: none;
  text-align: unset;
  border-radius: unset;
}

.btn-no-style:hover {
  cursor: pointer;
}
