.phone-number-input-custom-styles .PhoneInputCountry {
  background-color: rgba(0,0,0,0.09);
  margin: 0;
  padding: 0 10px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.phone-number-input-custom-styles .PhoneInputInput {
  height: 35px;
  border: none;
  outline: unset;
  background-color: rgba(0,0,0,0.06);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.phone-number-input-custom-styles .PhoneInputInput:focus {
  outline: lightgray solid 1px;
}
